<template>
  <div class="image-viewer" :style="$attrs.style" :class="$attrs.class">
    <div class="preview-container">
      <img 
        ref="imagePreview"
        :src="imageUrl" 
        class="image-preview" 
        :alt="alt"
        @click="handleImageClick"
      >
      <div 
        v-show="hasVideo"
        class="live-badge" 
        @click="toggleVideo"
      >{{ isPlaying ? '返回照片' : 'LIVE' }}</div>
      <video 
        ref="videoPlayer"
        class="video-preview"
        :class="{ active: isPlaying }"
        @ended="handleVideoEnd"
        @click="handleImageClick"
      ></video>
      <p class="status-text" v-show="status">{{ status }}</p>
    </div>

    <!-- 添加预览模态框 -->
    <div class="preview-modal" v-if="showPreview" @click.self="closePreview">
      <div class="preview-content">
        <div class="preview-header">
          <div class="preview-controls">
            <div v-if="hasVideo" @click="togglePreviewVideo" class="preview-control">
              {{ isPreviewPlaying ? '返回照片' : 'LIVE' }}
            </div>
            <div 
              v-show="scale !== 1"
              class="preview-control reset-zoom-btn" 
              @click="resetZoom"
            >
              重置大小
            </div>
            <div class="preview-control download-btn" @click="handleDownload">
              下载
            </div>
          </div>
          <div class="close-btn" @click="closePreview">×</div>
        </div>
        <div class="preview-body" 
             @wheel="handleWheel"
             @touchstart="handleTouchStart"
             @touchmove="handleTouchMove"
             @touchend="handleTouchEnd"
             @mousedown="handleDragStart"
             @mousemove="handleDragMove"
             @mouseup="handleDragEnd"
             @mouseleave="handleDragEnd">
          <div class="preview-wrapper" :style="previewTransformStyle">
            <img 
              :src="imageUrl" 
              :alt="alt"
              class="preview-image"
              @dblclick="handleDoubleClick"
            >
            <video
              v-if="hasVideo"
              ref="previewVideoPlayer"
              :src="videoUrl"
              class="preview-video"
              :class="{ active: isPreviewPlaying }"
              @ended="handlePreviewVideoEnd"
              @pause="handlePreviewVideoPause"
            ></video>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageViewer',
  inheritAttrs: false,
  props: {
    src: {
      type: String,
      default: ''
    },
    alt: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      isLivePhoto: false,
      isPlaying: false,
      imageUrl: '',
      videoUrl: '',
      status: '',
      hasVideo: false,
      showPreview: false,
      isPreviewPlaying: false,
      isGif: false,
      scale: 1,
      translateX: 0,
      translateY: 0,
      isDragging: false,
      lastX: 0,
      lastY: 0,
      touchStartDistance: 0,
      initialScale: 1,
      minScale: 0.5,
      maxScale: 3,
    }
  },

  computed: {
    previewTransformStyle() {
      return {
        transform: `translate3d(${this.translateX}px, ${this.translateY}px, 0) scale(${this.scale})`,
        transition: this.isDragging ? 'none' : 'transform 0.15s cubic-bezier(0.4, 0, 0.2, 1)',
        willChange: 'transform'
      }
    }
  },

  watch: {
    src: {
      immediate: true,
      handler(newSrc) {
        if (newSrc) {
          this.imageUrl = newSrc
          this.analyzeLivePhoto(newSrc)
        }
      }
    }
  },

  methods: {
    async analyzeLivePhoto(source) {
      try {
        if (source.toLowerCase().endsWith('.gif')) {
          this.imageUrl = source;
          this.isGif = true;
          this.hasVideo = false;
          return;
        }

        if (source.toLowerCase().endsWith('.mov')) {
          this.handleLivePhotoMov(source);
          return;
        }

        if (source.toLowerCase().endsWith('.heic')) {
          const movUrl = source.replace('.heic', '.mov');
          await this.handleLivePhotoMov(movUrl);
          return;
        }

        const response = await fetch(source)
        const arrayBuffer = await response.arrayBuffer()
        const uint8Array = new Uint8Array(arrayBuffer)
        const textDecoder = new TextDecoder()
        const fileString = textDecoder.decode(uint8Array)
        
        const xmpStart = fileString.indexOf('<x:xmpmeta')
        const xmpEnd = fileString.indexOf('</x:xmpmeta>') + 12
        let videoStart = -1
        
        const patternArray = new TextEncoder().encode('ftyp')
        const ftypIndex = this.indexOfSubarray(uint8Array, patternArray) - 4

        if (xmpStart === -1 || xmpEnd === -1) {
          if (ftypIndex > 0) {
            videoStart = ftypIndex
          }
        } else {
          const xmpString = fileString.slice(xmpStart, xmpEnd)
          const matches = xmpString.matchAll(/<Container:Item[^>]*\bItem:Mime=["']video\/mp4["'][^>]*\bItem:Length=["'](\d+)["'][^>]*\/>/g)
          const matches_alt = xmpString.matchAll(/GCamera:MicroVideoOffset=["'](\d+)["']/g)
          
          let videoLength = null
          for (const match of matches) {
            videoLength = parseInt(match[1], 10)
          }
          for (const match of matches_alt) {
            videoLength = parseInt(match[1], 10)
          }

          if (videoLength !== null) {
            videoStart = arrayBuffer.byteLength - videoLength
          }
        }

        if (videoStart > 0 && videoStart < arrayBuffer.byteLength) {
          this.separateImageAndVideo(uint8Array, videoStart)
        } else {
          this.resetState()
        }
      } catch (error) {
        console.error('解析失败:', error)
        this.resetState()
      }
    },

    indexOfSubarray(array, subarray) {
      const firstByte = subarray[0]
      const subLength = subarray.length
      let startIndex = array.indexOf(firstByte)
      
      while (startIndex !== -1) {
        if (array.subarray(startIndex, startIndex + subLength)
            .every((value, index) => value === subarray[index])) {
          return startIndex
        }
        startIndex = array.indexOf(firstByte, startIndex + 1)
      }
      return -1
    },

    separateImageAndVideo(uint8Array, videoStart) {
      const jpegData = uint8Array.slice(0, videoStart)
      const mp4Data = uint8Array.slice(videoStart)
      
      const imageBlob = new Blob([jpegData], { type: 'image/jpeg' })
      const videoBlob = new Blob([mp4Data], { type: 'video/mp4' })
      
      this.imageUrl = URL.createObjectURL(imageBlob)
      this.videoUrl = URL.createObjectURL(videoBlob)
      
      this.$refs.videoPlayer.src = this.videoUrl
      this.hasVideo = true
      this.status = ''
    },

    resetState() {
      this.hasVideo = false
      this.isPlaying = false
      this.status = ''
      this.isGif = false
      if (this.$refs.videoPlayer) {
        this.$refs.videoPlayer.src = ''
      }
    },

    toggleVideo() {
      if (!this.hasVideo) return
      
      const video = this.$refs.videoPlayer
      if (!this.isPlaying) {
        video.style.display = 'block'
        setTimeout(() => {
          video.play()
          this.isPlaying = true
        }, 10)
      } else {
        video.pause()
        video.currentTime = 0
        video.style.display = 'none'
        this.isPlaying = false
      }
    },

    handleVideoEnd() {
      const video = this.$refs.videoPlayer
      video.style.display = 'none'
      this.isPlaying = false
    },

    handleImageClick() {
      this.showPreview = true
      if (this.isPlaying) {
        this.$nextTick(() => {
          this.isPreviewPlaying = true
          this.$refs.previewVideoPlayer?.play()
        })
      }
    },

    closePreview() {
      if (this.isPreviewPlaying) {
        this.$refs.previewVideoPlayer?.pause()
        this.isPreviewPlaying = false
      }
      this.showPreview = false
      this.resetZoom()
    },

    togglePreviewVideo() {
      if (!this.hasVideo) return
      
      if (this.isPreviewPlaying) {
        this.$refs.previewVideoPlayer.pause()
      } else {
        this.$refs.previewVideoPlayer.play()
      }
      this.isPreviewPlaying = !this.isPreviewPlaying
    },

    handlePreviewVideoEnd() {
      this.$refs.previewVideoPlayer.pause()
      this.isPreviewPlaying = false
    },

    handlePreviewVideoPause() {
      this.isPreviewPlaying = false
    },

    async handleLivePhotoMov(movUrl) {
      try {
        const imageUrl = movUrl.replace('.mov', '.heic');
        const jpgUrl = movUrl.replace('.mov', '.jpg');
        
        let imageResponse = await fetch(imageUrl);
        if (!imageResponse.ok) {
          imageResponse = await fetch(jpgUrl);
        }
        
        if (imageResponse.ok) {
          this.imageUrl = imageResponse.url;
          this.videoUrl = movUrl;
          this.hasVideo = true;
          if (this.$refs.videoPlayer) {
            this.$refs.videoPlayer.src = this.videoUrl;
          }
        } else {
          this.resetState();
        }
      } catch (error) {
        console.error('处理 Live Photo 失败:', error);
        this.resetState();
      }
    },

    async handleDownload() {
      try {
        let url = this.isPreviewPlaying ? this.videoUrl : this.imageUrl;
        let filename = this.getFileName(url);
        
        // 如果是Blob URL，需要先获取Blob数据
        if (url.startsWith('blob:')) {
          const response = await fetch(url);
          const blob = await response.blob();
          url = URL.createObjectURL(blob);
        }

        // 创建临时下载链接
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // 如果创建了新的Blob URL，需要释放
        if (url.startsWith('blob:') && url !== this.imageUrl && url !== this.videoUrl) {
          URL.revokeObjectURL(url);
        }
      } catch (error) {
        console.error('下载失败:', error);
      }
    },

    getFileName(url) {
      // 从URL或原始src中提取文件名
      let filename = this.src.split('/').pop() || 'download';
      if (this.isPreviewPlaying) {
        // 确保视频文件有正确的扩展名
        if (!filename.toLowerCase().endsWith('.mov') && !filename.toLowerCase().endsWith('.mp4')) {
          filename += '.mp4';
        }
      } else {
        // 确保图片文件有正确的扩展名
        if (!filename.toLowerCase().endsWith('.jpg') && 
            !filename.toLowerCase().endsWith('.jpeg') && 
            !filename.toLowerCase().endsWith('.gif')) {
          filename += '.jpg';
        }
      }
      return filename;
    },

    handleWheel(e) {
      e.preventDefault();
      const delta = e.deltaY * -0.01;
      const scaleDelta = delta * 0.3;
      this.zoom(scaleDelta, e.clientX, e.clientY);
    },

    handleTouchStart(e) {
      if (e.touches.length === 2) {
        // 双指缩放
        const touch1 = e.touches[0];
        const touch2 = e.touches[1];
        this.touchStartDistance = Math.hypot(
          touch2.clientX - touch1.clientX,
          touch2.clientY - touch1.clientY
        );
        this.initialScale = this.scale;
      } else if (e.touches.length === 1) {
        // 单指拖动
        this.isDragging = true;
        this.lastX = e.touches[0].clientX - this.translateX;
        this.lastY = e.touches[0].clientY - this.translateY;
      }
    },

    handleTouchMove(e) {
      e.preventDefault();
      if (e.touches.length === 2) {
        // 优化双指缩放的灵敏度
        const touch1 = e.touches[0];
        const touch2 = e.touches[1];
        const currentDistance = Math.hypot(
          touch2.clientX - touch1.clientX,
          touch2.clientY - touch1.clientY
        );
        
        // 添加阻尼效果使缩放更平滑
        const scaleFactor = 0.003;
        const scale = this.initialScale * (1 + (currentDistance - this.touchStartDistance) * scaleFactor);
        
        const centerX = (touch1.clientX + touch2.clientX) / 2;
        const centerY = (touch1.clientY + touch2.clientY) / 2;
        
        requestAnimationFrame(() => {
          this.setScale(scale, centerX, centerY);
        });
      } else if (e.touches.length === 1 && this.isDragging) {
        const x = e.touches[0].clientX - this.lastX;
        const y = e.touches[0].clientY - this.lastY;
        
        requestAnimationFrame(() => {
          this.setPosition(x, y);
        });
      }
    },

    handleTouchEnd() {
      this.isDragging = false;
      this.touchStartDistance = 0;
    },

    handleDragStart(e) {
      if (e.button !== 0) return; // 只响应左键
      this.isDragging = true;
      this.lastX = e.clientX - this.translateX;
      this.lastY = e.clientY - this.translateY;
    },

    handleDragMove(e) {
      if (!this.isDragging) return;
      const x = e.clientX - this.lastX;
      const y = e.clientY - this.lastY;
      this.setPosition(x, y);
    },

    handleDragEnd() {
      this.isDragging = false;
    },

    handleDoubleClick(e) {
      if (this.scale === 1) {
        // 使用动画过渡到2倍大小
        this.animateScale(2, e.clientX, e.clientY);
      } else {
        this.animateScale(1);
      }
    },

    zoom(delta, clientX, clientY) {
      const rect = this.$el.getBoundingClientRect();
      const x = clientX - rect.left;
      const y = clientY - rect.top;
      
      const newScale = Math.min(Math.max(this.scale + delta, this.minScale), this.maxScale);
      this.setScale(newScale, x, y);
    },

    setScale(newScale, centerX, centerY) {
      const rect = this.$el.getBoundingClientRect();
      const x = centerX - rect.left;
      const y = centerY - rect.top;

      // 限制缩放范围并使用更平滑的值
      newScale = Math.min(Math.max(newScale, this.minScale), this.maxScale);
      
      // 添加缩放阈值，避免微小变化
      if (Math.abs(this.scale - newScale) < 0.01) return;
      
      const scaleRatio = newScale / this.scale;
      let newTranslateX = x - (x - this.translateX) * scaleRatio;
      let newTranslateY = y - (y - this.translateY) * scaleRatio;

      // 使用 requestAnimationFrame 进行平滑过渡
      requestAnimationFrame(() => {
        this.scale = newScale;
        this.setPosition(newTranslateX, newTranslateY);
      });
    },

    setPosition(x, y) {
      const container = this.$el.querySelector('.preview-body');
      const image = this.$el.querySelector('.preview-image');
      if (!container || !image) return;

      const containerRect = container.getBoundingClientRect();
      const imageRect = image.getBoundingClientRect();

      const scaledWidth = imageRect.width * this.scale;
      const scaledHeight = imageRect.height * this.scale;

      const maxX = Math.max((scaledWidth - containerRect.width) / 2, 0);
      const maxY = Math.max((scaledHeight - containerRect.height) / 2, 0);

      this.translateX = Math.min(Math.max(x, -maxX), maxX);
      this.translateY = Math.min(Math.max(y, -maxY), maxY);

      if (scaledWidth <= containerRect.width) {
        this.translateX = 0;
      }
      if (scaledHeight <= containerRect.height) {
        this.translateY = 0;
      }
    },

    resetZoom() {
      this.scale = 1;
      this.translateX = 0;
      this.translateY = 0;
    },

    // 新增：添加平滑的缩放动画
    animateScale(targetScale, centerX = null, centerY = null) {
      const startScale = this.scale;
      const startTime = performance.now();
      const duration = 300; // 动画持续时间（毫秒）

      const animate = (currentTime) => {
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1);
        
        // 使用 easeOutCubic 缓动函数
        const easeProgress = 1 - Math.pow(1 - progress, 3);
        
        const currentScale = startScale + (targetScale - startScale) * easeProgress;
        
        if (centerX !== null && centerY !== null) {
          this.setScale(currentScale, centerX, centerY);
        } else {
          // 重置到原始大小时居中
          this.scale = currentScale;
          this.translateX = 0;
          this.translateY = 0;
        }

        if (progress < 1) {
          requestAnimationFrame(animate);
        }
      };

      requestAnimationFrame(animate);
    }
  },

  beforeDestroy() {
    if (this.videoUrl) URL.revokeObjectURL(this.videoUrl)
    if (this.imageUrl && this.imageUrl !== this.src) {
      URL.revokeObjectURL(this.imageUrl)
    }
  }
}
</script>

<style scoped>
.image-viewer {
  display: inline-block;
  max-width: 400px; /* 设置最大宽度 */
  width: 100%;
}

.preview-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  border-radius: 12px;
  overflow: hidden;
}

.image-preview {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
  &[src$=".gif"] {
    pointer-events: none;
  }
}

.video-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease;
  cursor: pointer;
}

.video-preview.active {
  opacity: 1;
}

.live-badge {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 8px 20px;
  border-radius: 24px;
  font-size: 14px;
  z-index: 10;
  cursor: pointer;
  backdrop-filter: blur(4px);
  transition: all 0.3s ease;
}

.live-badge:hover {
  background-color: rgba(0, 0, 0, 0.85);
  transform: scale(1.05);
}

.status-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  z-index: 20;
  margin: 0;
}

@media (max-width: 600px) {
  .preview-container {
    aspect-ratio: 3/4;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .preview-container {
    aspect-ratio: 4/3;
  }
}

@media (min-width: 1025px) {
  .preview-container {
    aspect-ratio: 4/3;
    max-height: 80vh;
  }
}

.preview-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.preview-content {
  position: relative;
  max-width: 90vw;
  max-height: 90vh;
}

.preview-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

.preview-controls {
  display: flex;
  gap: 10px;
}

.preview-control {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  backdrop-filter: blur(4px);
  font-size: 14px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.preview-control:hover {
  background: rgba(0, 0, 0, 0.7);
  transform: scale(1.05);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.preview-control:active {
  transform: scale(0.98);
}

.download-btn {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  backdrop-filter: blur(4px);
  font-size: 14px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.download-btn:hover {
  background: rgba(0, 0, 0, 0.7);
  transform: scale(1.05);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.download-btn:active {
  transform: scale(0.98);
}

.close-btn {
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 0 8px;
}

.preview-body {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
  touch-action: none;
  background-color: transparent;
}

.preview-wrapper {
  will-change: transform;
  transform-origin: center;
  user-select: none;
  cursor: move;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  backface-visibility: hidden; /* 提高性能 */
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
}

.preview-image {
  max-width: 90vw;
  max-height: 90vh;
  object-fit: contain;
  pointer-events: none;
  transform-origin: center;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.preview-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0;
  transition: all 0.3s ease;
  transform: scale(1.02);
}

.preview-video.active {
  opacity: 1;
  transform: scale(1);
}

.preview-video.active + .preview-image {
  opacity: 0;
}

@media (max-width: 768px) {
  .preview-content {
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
  }
  
  .preview-image,
  .preview-video {
    width: 100%;
    height: 100%;
  }
}

.reset-zoom-btn {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  backdrop-filter: blur(4px);
  font-size: 14px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.reset-zoom-btn:hover {
  background: rgba(0, 0, 0, 0.7);
  transform: scale(1.05);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.reset-zoom-btn:active {
  transform: scale(0.98);
}
</style> 